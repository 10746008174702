<template>
  <div>
    <page-guide :options="options" :slogan="slogan" :pc-image="backgroundImage.pcImage" :mobile-image="backgroundImage.mobileImage"></page-guide>

    <div class="logoMeanBox pshow">
      <u-animate-container class="feelhappy">
        <u-animate name="bounceIn" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <img src="https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/feelhappy.png" alt="">
        </u-animate>
      </u-animate-container>
      <logomean></logomean>
      <u-animate-container class="creatqj">
        <u-animate name="bounceIn" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <img src="https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/creatqj.png" alt="">
        </u-animate>
      </u-animate-container>
    </div>

    <div class="toDoBox">
      <div class="titleBox mshow">
        <span class="choose-line"></span>
        <u-animate-container>
          <u-animate name="fadeInUp" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
            <p>我们要做什么</p>
          </u-animate>
        </u-animate-container>
      </div>
      <p class="content">{{ toDo }}</p>
    </div>

    <brand-value class="pshow"></brand-value>
    <div class="brand_values_box mshow">
      <div class="brand_values">
        <div class="titleBox mshow">
          <u-animate-container>
            <u-animate name="fadeInRight" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
              <span class="choose-line"></span>
              <p class="choose-title">迈吉客科技品牌价值观</p>
              <img class="choose-img" :src="brandValues" alt="">
            </u-animate>
          </u-animate-container>
        </div>
      </div>
    </div>

    <choose-reasons></choose-reasons>

    <recruiting-job></recruiting-job>

    <m-footer></m-footer>
  </div>
</template>

<script>
import pageGuide from '@/components/page-guide';
import logomean from "@/view/join/logomean";
import brandValue from "@/view/join/brand-values";
import chooseReasons from "@/view/join/chooseReasons"
import recruitingJob from "@/view/join/recruiting-job"
import footer from "@/components/footer";
import { toDo, brandValues } from "@/assets/js/fixed_data"

export default {
  name: "index",
  components: {
    pageGuide,
    logomean,
    brandValue,
    chooseReasons,
    recruitingJob,
    'm-footer': footer
  },
  data() {
    return {
      options: [{
        content: 'Magics',
        animate: 'fadeIn',
        delay: '0s'
      }, {
        content: '让虚拟',
        animate: 'fadeIn',
        delay: '0.8s'
      }, {
        content: '照进',
        animate: 'fadeIn',
        delay: '1s'
      }, {
        content: '现实',
        animate: 'fadeIn',
        delay: '0.7s'
      }],
      slogan:'为每家企业创造通往元宇宙的智能商业体',
      backgroundImage: {
        pcImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/backgroundother.png',
        mobileImage: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/02A.png'
      },
      toDo: toDo,
      brandValues: brandValues
    }
  },
}
</script>

<style scoped lang="less">
.titleBox {
  height: .8rem;
  margin: 0 auto;
  text-align: center;
  font-weight: 500;

  .choose-line {
    width: .7rem;
    height: 3px;
    background: rgba(119, 118, 255, 1);
    border-radius: 27px;
    display: inline-block;
  }
}

.logoMeanBox {
  width: 10.28rem;
  margin: 0 auto;
  position: relative;

  .feelhappy {
    position: absolute;
    top: 120px;
    left: 0;
  }

  .creatqj {
    position: absolute;
    top: 120px;
    right: 0;
  }
}

.toDoBox {
  width: 100%;
  font-size: 0;

  .content {
    width: 10.28rem;
    margin: 0 auto;
    text-align: left;
    font-size: .16rem;
    font-weight: 400;
    /*color:rgba(255,255,255,1);*/
    line-height: .35rem;

    padding: 20px;
    border-radius: 7px;
    background: #ffffffeb;
    color: #162a4eeb;
    box-shadow: 0px 2px 25px 0px rgba(205, 205, 255, 0.5);
  }

  .choose-Magics {
    width: 3.18rem;
    height: .91rem;
    left: 1.26rem;
    bottom: .85rem;
  }
}

.brand_values_box {
  width: 100%;
  height: 7.1rem;
  background: rgba(248, 248, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;

  .brand_values {
    width: 1000px;
    height: 5rem;
    position: relative;

    .choose-bg {
      width: 6.29rem;
      height: 3.68rem;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .choose-content {
      width: 4.66rem;
      height: 3.66rem;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 2px 25px 0px rgba(205, 205, 255, 0.5);
      border-radius: 6px;
      position: relative;
      z-index: 11;
      margin: 0 0 0 auto;
      font-size: 0;
      padding: .5rem .4rem 0;

      .choose-01 {
        width: .76rem;
        height: .72rem;
        position: absolute;
        z-index: 11;
        right: .3rem;
        top: .36rem;
      }

      .choose-line {
        width: .7rem;
        height: 3px;
        background: rgba(119, 118, 255, 1);
        border-radius: 1px;
        display: inline-block;
      }

      .choose-title {
        font-size: .22rem;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        margin: .1rem 0 .5rem;
      }

      .choose-img {
        width: 3.66rem;
        display: block;
        margin: 0 auto;
      }
    }
  }

}

.pshow {
  display: block;
}

.mshow {
  display: none !important;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .pshow {
    display: none;
  }

  .mshow {
    display: block !important;
  }

  .titleBox {
    //height: .33rem;
    margin: 0 auto;
    font-size: 20px;

    .choose-line {
      margin: 0 auto .1rem;
    }
  }

  .toDoBox {
    margin-top: 0;
    width: calc(100% - 0.68rem);
    padding: 0.34rem;

    .content {
      width: calc(100% - 0.4rem);
      margin: 0 auto;
      text-align: center;
      font-size: 14px;
      line-height: .35rem;
    }

    .choose-Magics {
      width: 1.18rem;
      height: .41rem;
      position: absolute;
      left: 1.5rem;
      bottom: .3rem;
    }
  }

  .brand_values_box {
    width: calc(100% - .4rem);
    height: 2.9rem;
    background: rgba(248, 248, 255, 1);
    padding: .5rem .2rem;

    .brand_values {
      width: calc(100% - .2rem);
      background: rgba(255, 255, 255, 1);
      padding: 0.2rem 0.1rem 0;
      height: 2.7rem;
      box-shadow: 0px 2px 25px 0px rgba(205, 205, 255, 0.5);
      border-radius: 6px;

      .choose-img {
        //width: 90%;
        height: 1.8rem;
        margin-top: 20px;
      }
    }

  }

  .needBox {
    width: calc(100% - .4rem);
    padding: .2rem;
    margin: 0 auto;

    .need-title {
      font-size: 20px;
      margin-bottom: .3rem;
    }

    .need-collapse {
      border-radius: 8px;

      .collapse-title {
        height: .25rem;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: .25rem;
      }

      /deep/ .el-collapse-item__header {
        height: .55rem;
        padding: 0 .2rem;
      }

      .collapse-content {
        padding: .1rem .2rem;
        background: rgba(248, 248, 255, 1);

        .title {
          font-size: 14px;
        }

        .collapse-left {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }

}
</style>
