<template>
  <div class="needBox" v-if="jobs.length">
    <el-collapse :accordion="true" v-model="activeNames" class="need-collapse">
      <el-collapse-item v-for="(job,i) in jobs" :key="i" :title="job.name" :name="i++">
        <template slot="title">
          <p class="collapse-title">{{ job.name }}</p>
        </template>

        <div class="collapse-content clearfix">
          <div class="pshow join-email right">
            投递简历：
            <a href="mailto:talent@magics.ai?subject=test&cc=&subject=简历投递&body=">
              talent@magics.ai</a>
          </div>
          <div class="title">
            <span>| 工作经验：{{ job.experience }}</span>
            <span>| 需求人数：{{ job.number }}</span>
            <span>| 学历：{{ job.educational_background }}</span>
          </div>

          <div class="collapse-left">
            <h4>岗位描述：</h4>
            <p v-html="job.responsibilities"></p>
          </div>
          <div class="collapse-left">
            <h4>岗位要求:</h4>
            <p v-html="job.requirements"></p>
          </div>

        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import { requestServices } from '@/api/api'

export default {
  name: "recruiting-job",
  data() {
    return {
      activeNames: ['1'],
      jobs: []
    };
  },
  created() {
    this.job();
  },
  methods: {
    job() {
      requestServices.jobsList().then(res => {
        this.jobs = res.result.jobs;
      })
    }
  }
}
</script>

<style scoped lang="less">
.needBox {
  width: 10.28rem;
  margin: 0 auto;
  padding: .7rem;

  .need-title {
    font-size: .24rem;
    font-weight: 500;
    color: rgba(51, 51, 51, 1);
    text-align: center;
    margin-bottom: .6rem;
  }

  .need-collapse {
    border-radius: 16px;
    box-shadow: 0px 2px 25px 0px rgba(205, 205, 255, 0.5);
    overflow: hidden;

    .collapse-title {
      height: .25rem;
      font-size: .18rem;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51, 51, 51, 1);
      line-height: .25rem;
    }

    /deep/ .el-collapse-item__header {
      height: .69rem;
      padding: 0 .5rem;
    }

    /deep/ .el-collapse-item__content {
      padding: 0;
    }

    .collapse-content {
      padding: .1rem .5rem;
      background: rgba(248, 248, 255, 1);

      .title {
        font-size: .16rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
      }

      .collapse-left {
        h4 {
          font-size: .14rem;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: rgba(51, 51, 51, 1);
          margin: .1rem 0 .1rem;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
      }
    }
  }
}

.join-email {
  font-size: 14px;
  color: black;

  a {
    color: #3a8ee6;

    &:hover {
      text-decoration: none;
      color: #66b1ff;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .needBox {
    width: calc(100% - .4rem);
    padding: .2rem;
    margin: 0 auto;

    .need-title {
      font-size: 20px;
      margin-bottom: .3rem;
    }

    .need-collapse {
      border-radius: 8px;

      .collapse-title {
        height: .25rem;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: .25rem;
      }

      /deep/ .el-collapse-item__header {
        height: .55rem;
        padding: 0 .2rem;
      }

      .collapse-content {
        padding: .1rem .2rem;
        background: rgba(248, 248, 255, 1);

        .title {
          font-size: 14px;
        }

        .collapse-left {
          h4 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
