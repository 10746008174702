var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logoBox",class:{'logoBox_active':_vm.logomeanShow},on:{"click":_vm.logomeanChange,"mouseenter":_vm.logomeanIn,"mouseleave":_vm.logomeanLeave}},[_c('img',{staticClass:"tutengmean",attrs:{"src":"https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/tutengmean.png","alt":""}}),_c('div',{staticClass:"tipBox"},[_c('el-popover',{ref:"popoverEar",attrs:{"placement":"right","width":"300","popper-class":"my-popper","popper-options":{
        boundariesElement:'body'
      },"trigger":"hover"},model:{value:(_vm.visibleEar),callback:function ($$v) {_vm.visibleEar=$$v},expression:"visibleEar"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":12}},[_c('img',{staticClass:"pop-ear",attrs:{"src":require("../../assets/home/ear.png"),"alt":""}})]),_c('el-col',{attrs:{"span":12}},[_c('transition',{attrs:{"enter-active-class":"animated fadeInDown","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleEar)?_c('p',{staticClass:"pop-ear-text"},[_vm._v("特立独行")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInUp","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleEar)?_c('p',{staticClass:"pop-ear-text"},[_vm._v("积极向上")]):_vm._e()])],1)],1)],1),_c('img',{directives:[{name:"popover",rawName:"v-popover:popoverEar",arg:"popoverEar"}],staticClass:"item-ear",attrs:{"src":require("../../assets/home/ear.png"),"alt":""}}),_c('el-popover',{ref:"popoverWuxian",attrs:{"placement":"left-end","offset":10,"width":"250","popper-options":{
        boundariesElement:'body'
      },"popper-class":"my-popper","trigger":"hover"},model:{value:(_vm.visibleWuxian),callback:function ($$v) {_vm.visibleWuxian=$$v},expression:"visibleWuxian"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":10}},[_c('img',{staticClass:"pop-ear",attrs:{"src":require("../../assets/home/wuxian.png"),"alt":""}})]),_c('el-col',{attrs:{"span":14}},[_c('transition',{attrs:{"enter-active-class":"animated fadeInUp","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleWuxian)?_c('p',{staticClass:"pop-ear-text",staticStyle:{"margin-top":"15px"}},[_vm._v("创新.无限可能")]):_vm._e()])],1)],1)],1),_c('img',{directives:[{name:"popover",rawName:"v-popover:popoverWuxian",arg:"popoverWuxian"}],staticClass:"item-wuxian",attrs:{"src":require("../../assets/home/wuxian.png"),"alt":""}}),_c('el-popover',{ref:"popoverMouse",attrs:{"placement":"bottom-start","offset":30,"width":"300","popper-options":{
        boundariesElement:'body'
      },"popper-class":"my-popper","trigger":"hover"},model:{value:(_vm.visibleMouse),callback:function ($$v) {_vm.visibleMouse=$$v},expression:"visibleMouse"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('img',{staticClass:"pop-mouse",attrs:{"src":require("../../assets/home/mouse.png"),"alt":""}})]),_c('el-col',{attrs:{"span":16}},[_c('transition',{attrs:{"enter-active-class":"animated fadeInDown","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleMouse)?_c('p',{staticClass:"pop-ear-text",staticStyle:{"margin-top":"10px"}},[_vm._v("好奇心/想象力/求知欲")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInUp","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleMouse)?_c('p',{staticClass:"pop-mouse-text"},[_vm._v(" 来源于爱因斯坦吐舌头")]):_vm._e()])],1)],1)],1),_c('img',{directives:[{name:"popover",rawName:"v-popover:popoverMouse",arg:"popoverMouse"}],staticClass:"item-mouse",attrs:{"src":require("../../assets/home/mouse.png"),"alt":""}}),_c('el-popover',{ref:"popoverShuma",attrs:{"placement":"bottom-start","offset":400,"width":"300","popper-options":{
        boundariesElement:'body',
      },"popper-class":"my-popper","trigger":"hover"},model:{value:(_vm.visibleShuma),callback:function ($$v) {_vm.visibleShuma=$$v},expression:"visibleShuma"}},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":8}},[_c('img',{staticClass:" pop-shuma Rotation",attrs:{"src":require("../../assets/home/shuma.png"),"alt":""}})]),_c('el-col',{attrs:{"span":16}},[_c('transition',{attrs:{"enter-active-class":"animated fadeInDown","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleShuma)?_c('p',{staticClass:"pop-ear-text",staticStyle:{"margin-top":"10px"}},[_vm._v("0/1数码科技")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInUp","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleShuma)?_c('p',{staticClass:"pop-ear-text"},[_vm._v("连接")]):_vm._e()])],1)],1)],1),_c('img',{directives:[{name:"popover",rawName:"v-popover:popoverShuma",arg:"popoverShuma"}],staticClass:"item-shuma",attrs:{"src":require("../../assets/home/shuma.png"),"alt":""}}),_c('el-popover',{ref:"popoverStar",attrs:{"placement":"left-start","offset":600,"width":"250","popper-options":{
        boundariesElement:'body',
      },"popper-class":"my-popper","trigger":"hover"},model:{value:(_vm.visibleStar),callback:function ($$v) {_vm.visibleStar=$$v},expression:"visibleStar"}},[_c('transition',{attrs:{"enter-active-class":"animated fadeInLeft","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleStar)?_c('p',{staticClass:"pop-star-text"},[_vm._v(" 眼中有光 心中有爱，也代表不完美 ")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInRight","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleStar)?_c('p',{staticClass:"pop-star-text"},[_vm._v(" 两个不完美可以形成完美组合 ")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInLeft","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleStar)?_c('p',{staticClass:"pop-star-text"},[_vm._v(" 完美是追求卓越 精益求精的过程 ")]):_vm._e()])],1),_c('div',{directives:[{name:"popover",rawName:"v-popover:popoverStar",arg:"popoverStar"}],staticClass:"item-star"},[_vm._m(0)]),_c('el-popover',{ref:"popoverStarRight",attrs:{"placement":"right","offset":400,"width":"250","popper-options":{
        boundariesElement:'body',
      },"popper-class":"my-popper","trigger":"hover"},model:{value:(_vm.visibleStarRight),callback:function ($$v) {_vm.visibleStarRight=$$v},expression:"visibleStarRight"}},[_c('transition',{attrs:{"enter-active-class":"animated fadeInLeft","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleStarRight)?_c('p',{staticClass:"pop-star-text"},[_vm._v(" 眼中有光 心中有爱，也代表不完美 ")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInRight","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleStarRight)?_c('p',{staticClass:"pop-star-text"},[_vm._v(" 两个不完美可以形成完美组合 ")]):_vm._e()]),_c('transition',{attrs:{"enter-active-class":"animated fadeInLeft","leave-active-class":"animated zoomOut","duration":{enter: 1000, leave: 300}}},[(_vm.visibleStarRight)?_c('p',{staticClass:"pop-star-text"},[_vm._v(" 完美是追求卓越 精益求精的过程 ")]):_vm._e()])],1),_c('div',{directives:[{name:"popover",rawName:"v-popover:popoverStarRight",arg:"popoverStarRight"}],staticClass:"item-star item-star-right "},[_vm._m(1)])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer-circle"},[_c('div',{staticClass:"inner-circle"}),_c('span'),_c('span'),_c('span'),_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"outer-circle"},[_c('div',{staticClass:"inner-circle"}),_c('span'),_c('span'),_c('span'),_c('span')])}]

export { render, staticRenderFns }