<template>
  <div>
    <div class="pshow">
      <div class="chooseBox">
        <img class="choose-bg" :src="chooseReasonsBg" alt="">
        <u-animate-container>
          <u-animate name="fadeInRight" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
            <div class="choose-content">
              <span class="choose-line"></span>
              <p class="choose-title">为什么选择我们？</p>
              <p v-for="(text,idx) in chooseReasons" :key="idx" class="choose-text">{{ text }}</p>
            </div>
          </u-animate>
        </u-animate-container>
      </div>
    </div>
    <div class="chooseBox mshow">
      <u-animate-container>
        <u-animate name="fadeInUp" delay="0s" duration="1s" :iteration="1" :offset="0" animateClass="animated" :begin="false">
          <div class="choose-content">
            <span class="choose-line"></span>
            <p class="choose-title">为什么选择我们？</p>
            <p v-for="(text,idx) in chooseReasons" :key="idx" class="choose-text">{{ text }}</p>
          </div>
        </u-animate>
      </u-animate-container>
    </div>
  </div>
</template>

<script>
import { chooseReasonsBg, chooseReasons } from "@/assets/js/fixed_data"

export default {
  name: "chooseReasons",
  data() {
    return {
      chooseReasonsBg: chooseReasonsBg,
      chooseReasons: chooseReasons
    }
  }
}
</script>

<style scoped lang="less">
.chooseBox {
  width: 9.28rem;
  height: 4.18rem;
  position: relative;
  margin: 0 auto;

  .choose-bg {
    width: 6.29rem;
    height: 3.28rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .choose-content {
    background: #ffffffeb;
    color: #162a4eeb;
    width: 4.66rem;
    height: 2.58rem;
    /*background:rgba(255,255,255,1);*/
    box-shadow: 0px 2px 25px 0px rgba(205, 205, 255, 0.5);
    border-radius: 6px;
    position: relative;
    z-index: 11;
    margin: 0 0 0 auto;
    font-size: 0;
    padding: .5rem .4rem 0;

    .choose-01 {
      width: .76rem;
      height: .72rem;
      position: absolute;
      z-index: 11;
      right: .3rem;
      top: .36rem;
    }

    .choose-line {
      width: .7rem;
      height: 3px;
      background: rgba(119, 118, 255, 1);
      border-radius: 1px;
      display: inline-block;
    }

    .choose-title {
      font-size: .22rem;
      font-weight: 500;
      margin: .1rem 0 .4rem;
    }

    .choose-text {
      font-size: .14rem;
      font-weight: 400;
      line-height: 27px;
    }
  }
}

.pshow {
  display: block;
}

.mshow {
  display: none !important;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .pshow {
    display: none;
  }

  .mshow {
    display: block !important;
  }

  .chooseBox {
    width: calc(100% - 0.4rem);
    height: 2.8rem;
    margin: .5rem .2rem;

    .choose-content {
      width: calc(100% - 0.4rem);
      height: 2.8rem;
      padding: .2rem .2rem 0;
      text-align: center;

      .choose-01 {
        width: .5rem;
        height: .5rem;
        right: .1rem;
        top: .1rem;
      }

      .choose-line {
        width: .7rem;
      }

      .choose-title {
        font-size: 20px;
        font-weight: 500;
        margin: .1rem 0 .2rem;
      }

      .choose-text {
        text-align: left;
        font-size: 14px;
      }
    }
  }
}
</style>
