<template>
  <div @click="logomeanChange"
       @mouseenter="logomeanIn"
       @mouseleave="logomeanLeave"
       class="logoBox" :class="{'logoBox_active':logomeanShow}">
    <img class="tutengmean" src="https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/tutengmean.png" alt="">
    <div class="tipBox">
      <el-popover
        ref="popoverEar"
        placement="right"
        v-model="visibleEar"
        width="300"
        popper-class="my-popper"
        :popper-options="{
          boundariesElement:'body'
        }"
        trigger="hover">
          <el-row :gutter="20">
            <el-col :span="12">
              <img class="pop-ear" src="../../assets/home/ear.png" alt="">
            </el-col>
            <el-col :span="12">
              <transition
                enter-active-class="animated fadeInDown"
                leave-active-class="animated zoomOut"
                :duration="{enter: 1000, leave: 300}">
                <p class="pop-ear-text" v-if="visibleEar">特立独行</p>
              </transition>
              <transition
                enter-active-class="animated fadeInUp"
                leave-active-class="animated zoomOut"
                :duration="{enter: 1000, leave: 300}">
                <p class="pop-ear-text" v-if="visibleEar">积极向上</p>
              </transition>
            </el-col>
          </el-row>
      </el-popover>
      <img v-popover:popoverEar class="item-ear" src="../../assets/home/ear.png" alt="">


      <el-popover
        ref="popoverWuxian"
        placement="left-end"
        :offset="10"
        v-model="visibleWuxian"
        width="250"
        :popper-options="{
          boundariesElement:'body'
        }"
        popper-class="my-popper"
        trigger="hover">
        <el-row :gutter="20">
          <el-col :span="10">
            <img class="pop-ear" src="../../assets/home/wuxian.png" alt="">
          </el-col>
          <el-col :span="14">
            <transition
              enter-active-class="animated fadeInUp"
              leave-active-class="animated zoomOut"
              :duration="{enter: 1000, leave: 300}">
                <p class="pop-ear-text" style="margin-top: 15px" v-if="visibleWuxian">创新.无限可能</p>
            </transition>
          </el-col>
        </el-row>
      </el-popover>
      <img v-popover:popoverWuxian class="item-wuxian" src="../../assets/home/wuxian.png" alt="">


      <el-popover
        ref="popoverMouse"
        placement="bottom-start"
        :offset="30"
        v-model="visibleMouse"
        width="300"
        :popper-options="{
          boundariesElement:'body'
        }"
        popper-class="my-popper"
        trigger="hover">
        <el-row :gutter="20">
          <el-col :span="8">
            <img class="pop-mouse" src="../../assets/home/mouse.png" alt="">
          </el-col>
          <el-col :span="16">
            <transition
              enter-active-class="animated fadeInDown"
              leave-active-class="animated zoomOut"
              :duration="{enter: 1000, leave: 300}">
              <p class="pop-ear-text" style="margin-top: 10px" v-if="visibleMouse">好奇心/想象力/求知欲</p>
            </transition>
            <transition
              enter-active-class="animated fadeInUp"
              leave-active-class="animated zoomOut"
              :duration="{enter: 1000, leave: 300}">
              <p class="pop-mouse-text" v-if="visibleMouse"> 来源于爱因斯坦吐舌头</p>
            </transition>

          </el-col>
        </el-row>
      </el-popover>
      <img v-popover:popoverMouse class="item-mouse" src="../../assets/home/mouse.png" alt="">


      <el-popover
        ref="popoverShuma"
        placement="bottom-start"
        v-model="visibleShuma"
        :offset="400"
        width="300"
        :popper-options="{
          boundariesElement:'body',
        }"
        popper-class="my-popper"
        trigger="hover">
        <el-row :gutter="20">
          <el-col :span="8">
            <img class=" pop-shuma Rotation" src="../../assets/home/shuma.png" alt="">
          </el-col>
          <el-col :span="16">
            <transition
              enter-active-class="animated fadeInDown"
              leave-active-class="animated zoomOut"
              :duration="{enter: 1000, leave: 300}">
              <p class="pop-ear-text" style="margin-top: 10px" v-if="visibleShuma">0/1数码科技</p>
            </transition>
            <transition
              enter-active-class="animated fadeInUp"
              leave-active-class="animated zoomOut"
              :duration="{enter: 1000, leave: 300}">
              <p class="pop-ear-text" v-if="visibleShuma">连接</p>
            </transition>

          </el-col>
        </el-row>
      </el-popover>
      <img v-popover:popoverShuma class="item-shuma" src="../../assets/home/shuma.png" alt="">


      <el-popover
        ref="popoverStar"
        placement="left-start"
        v-model="visibleStar"
        :offset="600"
        width="250"
        :popper-options="{
          boundariesElement:'body',
        }"
        popper-class="my-popper"
        trigger="hover">

        <transition
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated zoomOut"
          :duration="{enter: 1000, leave: 300}">
          <p class="pop-star-text" v-if="visibleStar">
            眼中有光 心中有爱，也代表不完美
          </p>
        </transition>
        <transition
          enter-active-class="animated fadeInRight"
          leave-active-class="animated zoomOut"
          :duration="{enter: 1000, leave: 300}">
          <p class="pop-star-text" v-if="visibleStar">
            两个不完美可以形成完美组合
          </p>
        </transition>
        <transition
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated zoomOut"
          :duration="{enter: 1000, leave: 300}">
          <p class="pop-star-text" v-if="visibleStar">
            完美是追求卓越 精益求精的过程
          </p>
        </transition>
      </el-popover>

        <div v-popover:popoverStar class="item-star">
          <div class="outer-circle">
            <div class="inner-circle"></div>

            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>


      <el-popover
        ref="popoverStarRight"
        placement="right"
        v-model="visibleStarRight"
        :offset="400"
        width="250"
        :popper-options="{
          boundariesElement:'body',
        }"
        popper-class="my-popper"
        trigger="hover">
        <transition
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated zoomOut"
          :duration="{enter: 1000, leave: 300}">
          <p class="pop-star-text" v-if="visibleStarRight">
            眼中有光 心中有爱，也代表不完美
          </p>
        </transition>
        <transition
          enter-active-class="animated fadeInRight"
          leave-active-class="animated zoomOut"
          :duration="{enter: 1000, leave: 300}">
          <p class="pop-star-text" v-if="visibleStarRight">
            两个不完美可以形成完美组合
          </p>
        </transition>
        <transition
          enter-active-class="animated fadeInLeft"
          leave-active-class="animated zoomOut"
          :duration="{enter: 1000, leave: 300}">
          <p class="pop-star-text" v-if="visibleStarRight">
            完美是追求卓越 精益求精的过程
          </p>
        </transition>
      </el-popover>
        <div v-popover:popoverStarRight class="item-star item-star-right ">
          <div class="outer-circle">
            <div class="inner-circle"></div>

            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

    </div>
  </div>
</template>
<script>

  export default {
    data(){
      return{
        visibleEar:false,
        visibleWuxian:false,
        visibleMouse:false,
        visibleShuma:false,
        visibleStar:false,
        visibleStarRight:false,
        logomeanShow:false,
      }
    },

    methods:{
      logomeanChange(){
        // this.logomeanShow=!this.logomeanShow;
        // this.$emit('logomeanActive',this.logomeanShow)
      },
      logomeanIn(){

      },
      logomeanLeave(){
        // this.$emit('logomeanActive',false)
        // this.logomeanShow=false
      }
    }
  }
</script>
<style lang="less" scoped>


  .pop-ear{
    width: 100%;
  }
  .pop-shuma{
    height: 60px;
    filter:hue-rotate(70deg);
    /*filter: brightness(0.5);*/
  }
  .pop-mouse{
    width: 60px;
  }
  .pop-ear-text{
    font-size: 20px;
    color: #350886;
    /*color: #fff;*/
    font-weight: 600;
    text-align: center;
  }
  .pop-mouse-text{
    font-size: 14px;
    color: #350886;
    /*color: #fff;*/
    font-weight: 400;
    text-align: center;
  }
  .pop-star-text{
    width: 100%;
    font-size: 14px;
    color: #350886;
    font-weight: 600;
    text-align: center;
  }



  .logoBox{
    transform: scale(0.8);
    margin: 50px auto ;
    width: 400px;
    height: 372px;
    position: relative;
    right: 0;
    transition: all 1s;
    .tutengmean{
      width: 400px;
    }
  }
  .logoBox_active{
    right: 2.4rem;
    transform: scale(1.3);
  }
  .tipBox{
    position: absolute;
    width: 394px;
    height: 371px;
    top: 0;
    left: 3px;
    opacity: 0.8;
    z-index: 11;
    .item-ear{
      width: 100%;
      position: absolute;
      top: -1px;
      right: -1px;
      &:hover{
        filter: drop-shadow(2px 4px 6px black);
        transition: all .5s;
      }
    }
    .item-wuxian{
      width: 100%;
      position: absolute;
      top: 95px;
      &:hover{
        filter: drop-shadow(2px 4px 6px black);
        transition: all .5s;
      }
    }
    .item-mouse{
      width: 75px;
      position: absolute;
      top: 269px;
      left: 160px;
      &:hover{
        filter: drop-shadow(2px 4px 6px black);
        transition: all .5s;
      }
    }

    .item-shuma{
      width: 99px;
      position: absolute;
      top: 182px;
      right: 60px;
      z-index: 12;
      &:hover{
        filter: drop-shadow(2px 4px 6px black);
        transition: all .5s;
      }
    }

  }
  .item-star{
    &:hover{
      opacity: 1;
      /*filter: drop-shadow(2px 4px 6px black);*/
      transition: all .5s;
    }
    background-color: #331649;
    width: 104px;
    height: 104px;
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    left: 61px;
    top: 157px;
    z-index: 8;
    opacity: 0;
    .outer-circle{
      width: 44px;
      height: 44px;
      border-radius: 50%;
      position: absolute;
      right: 10px;
      top: -1px;
      /*background: #fff;*/
      background: linear-gradient(#dacbf1, #c49bfc, #9f5ae3);
      animation: rotate 2.5s linear infinite;
      .inner-circle{
        position: relative;
        top: 0px;
        left: 0px;
        filter: blur(4px);
        z-index: 11;
        width: 44px;
        height: 44px;
        border-radius: 50%;
        background-color: #fff;
      }

      span {
        top: 0;
        position: absolute;
        display: inline-block;
        height: 44px;
        width: 44px;
        background: linear-gradient(#dacbf1, #c49bfc, #9f5ae3);
        border-radius: 50%;
      }
      span:nth-child(1) {
        filter: blur(5px);
      }

      span:nth-child(2) {
        filter: blur(10px);
      }

      span:nth-child(3) {
        filter: blur(25px);
      }

      span:nth-child(4) {
        filter: blur(75px);
      }
    }
  }
  @keyframes rotate {
    0% {
      filter: hue-rotate(0deg);
    }
    100% {
      filter: hue-rotate(360deg);
    }
  }

  .item-star-right{
    left: 231px;
  }
  .item-star-pop{
    opacity: 1;
  }



  @-webkit-keyframes rotation2{
    from {-webkit-transform: rotate(360deg);}
    to {-webkit-transform: rotate(0deg);}
  }
  .Rotation{
    -webkit-transform: rotate(360deg);
    animation: rotation2 15s linear infinite;
    -moz-animation: rotation2 15s linear infinite;
    -webkit-animation: rotation2 15s linear infinite;
    -o-animation: rotation2 15s linear infinite;
  }

</style>
