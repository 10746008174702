<template>
  <div class="jzgBox">
    <div  class="jzgBox2">
      <div class="titleBox">
        <span class="line"></span>
        <p class="title">迈吉客科技品牌价值观</p>
      </div>
      <el-row :gutter="30">
        <el-col :span="7">
          <u-animate-container>
            <u-animate
              name="fadeInLeft"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
              <div class="chooseBox_parent">
                <div class="chooseBox" style="margin-top: 68px">

                      <div class="choose-content">
                        <!--              <img class="choose-01" src="../../assets/image/i501.png" alt="">-->
                        <!--                    <span class="choose-line"></span>-->
                        <p class="choose-title">迈吉客基本原则</p>
                        <p class="choose-text">因为热爱，所以投入</p>
                        <p class="choose-text">开放心态，成长思维</p>
                        <p class="choose-text">坦诚直接，积极乐观</p>
                        <p class="choose-text">注重结果，实事求是</p>
                        <p class="choose-text">各抒己见，创意择优</p>
                        <p class="choose-text">共同参与，相互成就</p>
                        <p class="choose-text">责任感和主人翁精神</p>
                      </div>
                </div>
              </div>
            </u-animate>
          </u-animate-container>

        </el-col>
        <el-col :span="10">

          <div class="leftBox">
            <div class="roundBox" :class="{'Rotation':actionShow}">
              <div class="item1" :class="{'Rotation2':actionShow}">坦诚开放</div>
              <div class="item2" :class="{'Rotation2':actionShow}">平等尊重</div>
              <div class="item3" :class="{'Rotation2':actionShow}">信任协作</div>
              <div class="item4" :class="{'Rotation2':actionShow}">互利共赢</div>
              <div class="item5" :class="{'Rotation2':actionShow}">守正出奇</div>
              <span class="set_1_btn Vbtn-3 middle-word"
                    :class="{'Rotation2':actionShow}"
              >
                    <svg>
                      <rect x="0" y="0" fill="none" width="100%" height="100%"></rect>
                    </svg>
                    <p>Magics</p>
                    <p>价值观</p>
                  </span>


            </div>
            <div class="jtBox" :class="{'Rotation2':actionShow}">
              <i class="iconfont icon-jiantou jt1"></i>
              <i class="iconfont icon-jiantou jt2"></i>
              <i class="iconfont icon-jiantou jt3"></i>
              <i class="iconfont icon-jiantou jt4"></i>
              <i class="iconfont icon-jiantou jt5"></i>
            </div>
          </div>
        </el-col>
        <el-col :span="7">
          <u-animate-container>
            <u-animate
              name="fadeInRight"
              delay="0s"
              duration="1s"
              :iteration="1"
              :offset="0"
              animateClass="animated"
              :begin="false">
          <div class="chooseBox_parent">
            <div class="chooseBox">

                  <div class="choose-content">
                    <!--              <img class="choose-01" src="../../assets/image/i501.png" alt="">-->
<!--                    <span class="choose-line"></span>-->
                    <p class="choose-title">迈吉客管理者原则</p>
                    <p class="choose-text">外观大事，内省自身</p>
                    <p class="choose-text">深思熟虑，谋定后动</p>
                    <p class="choose-text">以身作则，身先士卒</p>
                    <p class="choose-text">有包容心，用人所长</p>
                    <p class="choose-text">知人善任，敢用牛人</p>
                    <p class="choose-text">公开公平，公正无私</p>
                    <p class="choose-text">引领团队，达成目标</p>
                    <p class="choose-text">能者上，平者让，庸者下</p>
                  </div>
            </div>
          </div>
            </u-animate>
          </u-animate-container>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
  export default{

    data(){
      return{
        actionShow:true,
      }
    },
    methods:{


    }
  }
</script>
<style lang="less" scoped>
  .chooseBox_parent{

    .chooseBox{
      margin-top: 50px;
      .choose-content{
        border-radius: 7px;
        background: #ffffffeb;
        color: #162a4eeb;
        box-shadow: 0px 2px 25px 0px rgba(205, 205, 255, 0.5);

        /*background:rgba(255,255,255,1);*/
        /*box-shadow:0px 2px 25px 0px rgba(205,205,255,0.5);*/
        /*border-radius:6px;*/
        position: relative;
        z-index: 11;
        font-size: 0;
        padding: 0.2rem;
        text-align: center;
        /*background: #b893fb;*/
        /*color: #fff;*/
        font-weight: 500;
        .choose-line{
          width:.7rem;
          height:3px;
          background:rgba(119,118,255,1);
          border-radius:1px;
          display: inline-block;
        }
        .choose-title{
          font-size:20px;
          font-weight:500;
          margin: .1rem 0 .1rem;
        }
        .choose-text{
          font-size: 14px;
          font-weight:400;

          line-height:27px;
        }
      }
    }
  }



  .titleBox{
    text-align: center;
    font-size: 0;
    .line{
      width: 0.7rem;
      height: 3px;
      background: #7776ff;
      border-radius: 27px;
      display: inline-block;
      margin-top: 1rem;
    }
    .title{
      height: 0.33rem;
      font-size: 0.24rem;
      font-weight: 500;
      line-height: 0.33rem;
      margin: 0.2rem 0 0.8rem;
    }
  }

  .set_1_btn {
    color: #333;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    width: 110px;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
  }
  .Vbtn-3 {
    background: transparent;
    text-align: center;
    float: left;
    &:hover {
      background: rgba(225, 51, 45, 0);
      font-weight: 900;
      letter-spacing: 1px;
      transition: all 150ms linear 0s;
    }
    svg {
      height: 50px;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      &:not(:root) {
        overflow: hidden;
      }
    }
    rect{
      fill: none;
      stroke: #FFF;
      /*stroke: #9a75df;*/
      stroke-width: 5;
      stroke-dasharray: 422, 0;
      transition: all 450ms linear 0s;
    }
    &:hover rect {
      stroke-width: 5;
      stroke-dasharray: 41, 187;
      stroke-dashoffset: 48;
      -webkit-transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
      transition: all 1.35s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }



  .jzgBox{
    width: 100%;
    height: 7rem;
    .jzgBox2{
      width: 10.28rem;
      margin: 0 auto;
      position: relative;

      .box-card1{
        .title{
          font-size: 20px;
        }
      }
    }
  }
  .leftBox{
    width: 330px;
    margin: 30px auto 0;
    position: relative;
    height: 330px;
    left: 0;
    transition: all 1s;
    transform: scale(1);
    opacity: 1;
  }
  .leftBox_active{
    left: 3.6rem;
    transform: scale(1.5);
  }
  .meanBox_active-leftBox{
    opacity: 0;
  }
  .meanBox{
    opacity: 1;
    transition: all .5s;
  }
  .leftBox_active-mean{
    opacity: 0;
  }

  .roundBox{
    font-size: 0;
    width: 330px;
    height: 330px;
    text-align: center;
    position: absolute;
    top: 0;
    z-index: 11;

    div{
      width: 40px;
      height: 40px;
      line-height: 20px;
      border-radius: 50%;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
      padding: 27px;
      text-align: center;
      background: linear-gradient(45deg, #7f36ff, #fcfaff);
      box-shadow: 0 0 10px 0px #7f36ff;
      display: inline-block;
      position: absolute;
    }
    .middle-word{
      width: 110px;
      height: 50px;
      top: 140px;
      color: #7944d7;
      left: 110px;
      font-weight: 600;
      display: block;
      position: absolute;
      font-size: 21px;
      /*box-shadow: 0px 0px 8px 1px #b0a3c7;*/
      border-radius: 5px;

      &:hover{
        cursor: pointer;
      }
    }
    .item1{
      background: linear-gradient(90deg, #7f36ff, #fcfaff);
      top: 99px;
      left: -5px;
    }
    .item2{
      bottom: 0px;
      left: 45px;
    }
    .item3{
      top: 0px;
      left: 120px;
    }
    .item4{
      bottom: 0px;
      right: 45px;
    }
    .item5{
      background: linear-gradient(-45deg, #7f36ff, #fcfaff);
      top: 99px;
      right: -5px;
    }
  }
  .jtBox{
    transition: all .5s;
    font-size: 0;
    width: 260px;
    height: 260px;
    position: absolute;
    top: 35px;
    left: 35px;
    z-index: 1;
    transform: rotateZ(35deg);
    /*background: url('https://timgsa.baidu.com/timg?image&quality=80&size=b9999_10000&sec=1590398024204&di=095d8af8170d8f591fea4d9b652bb64d&imgtype=0&src=http%3A%2F%2Fa.hiphotos.baidu.com%2Fzhidao%2Fpic%2Fitem%2Ff2deb48f8c5494eec0bb5e052df5e0fe99257e14.jpg') no-repeat;*/
    /*background-size: 100% 110%;*/

    /*z-index: 11111;*/
    i{
      font-size: 25px !important;
      color: #7775ff;
      position: absolute;
    }
    .jt1{
      top: 88px;
      left: 0;
      transform: rotateZ(-72deg);
    }
    .jt2{
      bottom: 0px;
      left: 50px;
      transform: rotateZ(-144deg);
    }
    .jt3{
      top: 0px;
      left: 120px;
    }
    .jt4{
      bottom: 0px;
      right: 48px;
      transform: rotateZ(144deg);
    }
    .jt5{
      top: 87px;
      right: 0;
      transform: rotateZ(72deg);
    }
  }


  @-webkit-keyframes rotation{
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
  }
  @-webkit-keyframes rotation2{
    from {-webkit-transform: rotate(360deg);}
    to {-webkit-transform: rotate(0deg);}
  }
  .Rotation{
    -webkit-transform: rotate(360deg);
    animation: rotation2 15s linear infinite;
    -moz-animation: rotation2 15s linear infinite;
    -webkit-animation: rotation2 15s linear infinite;
    -o-animation: rotation2 15s linear infinite;
  }
  .Rotation2{
    -webkit-transform: rotate(360deg);
    animation: rotation 15s linear infinite;
    -moz-animation: rotation 15s linear infinite;
    -webkit-animation: rotation 15s linear infinite;
    -o-animation: rotation 15s linear infinite;
  }
  .Rotation3{
    -webkit-transform: rotate(360deg);
    animation: rotation 15s linear infinite;
    -moz-animation: rotation 15s linear infinite;
    -webkit-animation: rotation 15s linear infinite;
    -o-animation: rotation 15s linear infinite;
  }
</style>
